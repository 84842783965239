import { createElement, forwardRef } from "react";
import type { FunctionComponent, HTMLAttributes, ReactNode } from "react";

import { cn } from "@kavval/ui/lib/utils";

interface ChipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: FunctionComponent<any> | string;
  className?: string;
  variant?: "outlined";
  color?: "primary" | "secondary" | "dark";
  size?: "small";
  children?: ReactNode;
  icon?: ReactNode;
}

const colorClasses = {
  default: "bg-snow-grey text-dark-blue Chip-default",
  primary: "bg-electric-blue text-white Chip-primary",
  secondary: "bg-orange text-white Chip-secondary",
  dark: "bg-dark-blue text-white Chip-dark",
};

const Chip = forwardRef<HTMLSpanElement, ChipProps & HTMLAttributes<"span">>((props, ref) => {
  const {
    component = "span",
    className,
    variant,
    color,
    size,
    children,
    icon,
    ...otherProps
  } = props;
  return createElement(
    component,
    {
      className: cn(
        "Chip",
        "inline-flex flex-row justify-center items-center align-middle whitespace-nowrap shrink-0",
        "gap-1 md:gap-2",
        "rounded-sm py-1  font-bold",
        component === "a" && "no-underline hover:no-underline",
        // Tweak avec "leading" pour mieux centrer le texte verticalement
        // (probablement à cause d'un pb dans la font)
        "h-6 px-2 leading-[90%] md:leading-1",
        size === "small" ? "font-bold text-[11px]" : "md:px-3 md:h-8 text-xs md:text-sm",
        colorClasses[color || "default"],
        variant === "outlined" &&
          "bg-transparent border border-snow-grey text-inherit Chip-outlined",
        className
      ),
      ...otherProps,
      ref,
    },
    <>
      {icon && <span className="inline-flex ChipIcon">{icon}</span>}

      <span
        // Tweak avec "margin-top" pour mieux centrer le texte verticalement
        // (probablement à cause d'un pb dans la font)
        className="-mt-[1px]"
      >
        {children}
      </span>
    </>
  );
});

Chip.displayName = "Chip";

export default Chip;
